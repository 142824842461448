import React, { useMemo } from 'react';
import { getThemeQuery } from 'data/queries/themes';
import { useEntityQuery } from '@rexlabs/model-generator';
import ClassicFooterSettings from 'view/forms/personas/classic/ClassicFooterSettings';
import VersatileFooterSettings from 'view/forms/personas/versatile/VersatileFooterSettings';
import { RenderLoading } from 'view/components/render-loading';
import { versatileFooterSettings } from 'view/screens/design/footer';
import MinimalFooterSettings from 'view/forms/personas/minimal/MinimalFooterSettings';

const FOOTER_COLOR_VARIANTS = {
  'primary-500': {
    label: 'Primary',
    value: { color: 'primary-500', hover: 'primary-hover' }
  },
  'primary-600': {
    label: 'Primary Light',
    value: { color: 'primary-600', hover: 'primary-500' }
  },
  'primary-400': {
    label: 'Primary Dark',
    value: { color: 'primary-400', hover: 'primary-500' }
  },
  'secondary-500': {
    label: 'Secondary',
    value: { color: 'secondary-500', hover: 'secondary-hover' }
  },
  'secondary-600': {
    label: 'Secondary Light',
    value: { color: 'secondary-600', hover: 'secondary-500' }
  },
  'secondary-400': {
    label: 'Secondary Dark',
    value: { color: 'secondary-400', hover: 'secondary-500' }
  },
  white: {
    label: 'White',
    value: { color: 'white', hover: 'light-hover' }
  },
  dark: {
    label: 'Black',
    value: { color: 'dark', hover: 'dark-hover' }
  }
};

const FOOTER_LOGO_VARIANTS = [
  { label: 'Primary Logo', value: 'desktop_logo' },
  { label: 'Secondary Logo', value: 'secondary_logo' },
  { label: 'Tertiary Logo', value: 'tertiary_logo' }
];

const CLASSIC_LOGO_VARIANTS = FOOTER_LOGO_VARIANTS?.slice(0, -1);

export const mapPropsToValues = (props) => {
  const { settings, forms, menus } = props;
  const settingsData = settings.list.items;

  const data = settingsData.reduce((acc, object) => {
    if (
      ![
        'hide_agency_details',
        'footer_address_line_1',
        'footer_address_line_2',
        'footer_postcode',
        'footer_state',
        'footer_country',
        'footer_phone',
        'footer_fax',
        'footer_email',
        'footer_agency_name',
        'footer_agency_opening_hours',
        'footer_form',
        'display_terms_and_conditions',
        'display_privacy_policy',
        'legal_menu',
        'legal_disclaimer',
        'unique_footer_logo',
        ...versatileFooterSettings
      ].includes(object.key)
    )
      return acc;

    const value = object?.value ? object?.value[0] : null;

    if (
      object.key === 'default_header_logo' ||
      object.key === 'unique_footer_logo'
    ) {
      switch (value) {
        case 'secondary_logo':
          acc[object.key] = FOOTER_LOGO_VARIANTS[1];
          return acc;
        case 'tertiary_logo':
          acc[object.key] = FOOTER_LOGO_VARIANTS[2];
          return acc;
        case 'desktop_logo':
        default:
          acc[object.key] = FOOTER_LOGO_VARIANTS[0];
          return acc;
      }
    }

    if (object.key === 'footer_form') {
      const formList = forms?.list?.items;
      const formItems = formList?.map((form) => ({
        value: form.id,
        label: form.name
      }));

      acc[object.key] = formItems?.find((i) => i.value === value?.id);
      return acc;
    }

    if (object.key === 'legal_menu') {
      const menuList = menus?.list?.items.map((item) => {
        return { label: item.name, value: item.id };
      });

      acc[object.key] = menuList?.find((i) => i.value === value?.id);
      return acc;
    }

    if (object.key === 'quick_links_menu') {
      const menuList = menus?.list?.items.map((item) => {
        return { label: item.name, value: item.id };
      });

      acc[object.key] = menuList?.find((i) => i.value === value?.id);
      return acc;
    }

    if (
      object?.value[0] &&
      ['footer_background_color', 'footer_font_color'].includes(object.key)
    ) {
      const fontColor = FOOTER_COLOR_VARIANTS[object.value[0].color];
      acc[object.key] = {
        label: fontColor.label,
        value: fontColor.value
      };
      return acc;
    }

    acc[object.key] = value;
    return acc;
  }, {});

  return data;
};

function EditFooterSettings({
  isSubmitting,
  submitForm,
  menus,
  variant,
  themeId,
  forms
}) {
  const themeQuery = useMemo(() => {
    if (themeId) return getThemeQuery(themeId);
  }, [themeId]);

  const { status: themeStatus, data: themeData } = useEntityQuery(themeQuery);

  return (
    <RenderLoading isLoading={themeStatus === 'loading'}>
      {themeData?.key === 'versatile' ? (
        <VersatileFooterSettings
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          menus={menus}
          variant={variant}
          footerColorVariants={Object.values(FOOTER_COLOR_VARIANTS)}
          logoVariants={Object.values(FOOTER_LOGO_VARIANTS)}
          forms={forms}
        />
      ) : null}
      {themeData?.key === 'minimal' ? (
        <MinimalFooterSettings
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          menus={menus}
          variant={variant}
          logoVariants={Object.values(FOOTER_LOGO_VARIANTS)}
          forms={forms}
        />
      ) : null}
      {themeData?.key === 'byron' ? (
        <ClassicFooterSettings
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          menus={menus}
          variant={variant}
          forms={forms}
          logoVariants={Object.values(CLASSIC_LOGO_VARIANTS)}
        />
      ) : null}
    </RenderLoading>
  );
}
export default EditFooterSettings;
